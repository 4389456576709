import { graphql } from "gatsby";
import React from "react";
import ArticleList from "../../components/investment-ideas/ArticleList";
import InvestmentIdeasLayout from "../../components/investment-ideas/InvestmentIdeasLayout";

const pageData = {
    title: 'Mirá nuestras Investment Ideas y empezá a invertir | Balanz',
    description: 'Nuestros especialistas crean constantemente Investment Ideas. Conocé donde están las oportunidades de inversión y todas nuestras recomendaciones.',
    bannerText: null
};

const InvestmenIdeasArts = ({data}) => {
    return <InvestmentIdeasLayout pageData={pageData}>
        <ArticleList artdata={data.allInvestmentArticles.edges} />
    </InvestmentIdeasLayout>
}

export const query = graphql`
    query {
        allInvestmentArticles {
            edges {
                node {
                    slug
                    title
                    description
                    category
                    date
                    timestamp
                    imgUrl
                    visits
                    order
                    metatitle
                    htmldesc
                    section
                    htmlcontent
                }
            }
        }
    }
`
export default InvestmenIdeasArts